:root {
  --primary-color: #05b1d0;
  --dark-color: #000410;
  --light-color: #fff;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Regular.7cfebe77.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Bold.88a04ef3.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("Inter-ExtraBold.27b787d8.ttf");
  font-weight: 800;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #18191f;
  font-weight: 700;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

.h4 {
  font-size: 20px;
  font-weight: 800;
}

.lead {
  font-size: 30px;
  font-weight: 400;
}

p {
  color: #18191f;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
}

header a, footer a {
  color: var(--light-color);
  margin-right: 1.5em;
  text-decoration: none;
}

header a:hover, header a:focus, footer a:hover, footer a:focus {
  color: var(--primary-color);
}

.header-bg {
  background-color: var(--dark-color);
  min-height: 600px;
  background-image: url("header_bg.bf036d59.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.header-bg h1 {
  color: var(--light-color);
  font-size: 72px;
  font-weight: 800;
}

.header-bg .lead {
  letter-spacing: .5px;
  color: var(--light-color);
  font-size: 20px;
}

.phone-demo {
  width: 320px;
  height: 640px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.phone-demo .phone-screen {
  z-index: 0;
  width: 279px;
  height: 604px;
  position: absolute;
  top: 17px;
}

.phone-demo .phone-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.phone-demo.with-hand {
  width: 504px;
  height: 599px;
  background-image: url("hand.81e908d9.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding-right: 165px;
}

.phone-demo.with-hand .phone-overlay {
  width: 240px;
  height: 485px;
}

.phone-demo.with-hand .phone-screen {
  width: 214px;
  height: 462px;
  top: 14px;
}

.numbered-bullet {
  background-color: var(--primary-color);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 50px;
}

.score-arrow:before, .fakenews-underline:before, .authentic-profile:before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.score-arrow:before {
  width: 170px;
  height: 200px;
  background-image: url("arrow.b86ccd35.svg");
  -webkit-transform: translateX(-200px)translateY(100px);
  transform: translateX(-200px)translateY(100px);
}

.fakenews-underline:before {
  width: 250px;
  height: 70px;
  background-image: url("underline.f032ccff.svg");
  -webkit-transform: translateX(-25px)translateY(15px);
  transform: translateX(-25px)translateY(15px);
}

.authentic-profile:before {
  width: 125px;
  height: 100px;
  background-image: url("sparkle.5a41be66.png");
  -webkit-transform: translateX(340px)translateY(-115px);
  transform: translateX(340px)translateY(-115px);
}

.arrow-right {
  max-width: 480px;
  margin-left: 25px;
  margin-right: 150px;
  position: relative;
}

.arrow-right:after {
  content: "";
  width: 142px;
  height: 46px;
  background-image: url("right-arrow-straight.eccc94de.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 250px;
  right: -100px;
}

.half-width {
  max-width: 500px;
}

footer {
  color: #fff;
  background-color: var(--dark-color);
}

footer nav {
  max-width: 300px;
}

footer p {
  color: var(--light-color);
}

footer a {
  margin-bottom: 1.5em;
  display: inline-block;
}

@media (width <= 1199px) {
  .authentic-profile:before {
    background-image: none;
    -webkit-transform: translateX(0)translateY(0);
    transform: translateX(0)translateY(0);
  }
}

@media (width <= 991px) {
  .header-bg h1 {
    font-size: 57px;
  }

  .half-width {
    max-width: none;
  }

  .arrow-right {
    margin: 0 auto;
  }

  .arrow-right:after {
    background-image: none;
    -webkit-transform: translateX(0)translateY(0);
    transform: translateX(0)translateY(0);
  }
}

@media (width <= 767px) {
  .header-bg h1 {
    font-size: 42px;
  }

  .score-arrow:before {
    background-image: none;
    -webkit-transform: translateX(0)translateY(0);
    transform: translateX(0)translateY(0);
  }
}

@media (width <= 575px) {
  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  .h4 {
    font-size: 17px;
  }

  p {
    font-size: 15px;
  }

  .lead {
    font-size: 17px;
  }

  .header-bg h1 {
    font-size: 34px;
  }

  .header-bg .lead {
    font-size: 17px;
  }

  .fakenews-underline:before {
    width: 150px;
  }

  .phone-demo {
    width: 227px;
    height: 454px;
  }

  .phone-demo .phone-screen {
    width: 197px;
    height: 428px;
    top: 13px;
  }

  .phone-demo.with-hand {
    width: 294px;
    height: 349px;
    padding-right: 95px;
  }

  .phone-demo.with-hand .phone-overlay {
    width: 140px;
    height: 283px;
  }

  .phone-demo.with-hand .phone-screen {
    width: 125px;
    height: 269px;
    top: 7px;
  }

  .fakenews-underline:before {
    -webkit-transform: translateX(-25px)translateY(8px);
    transform: translateX(-25px)translateY(8px);
  }
}

/*# sourceMappingURL=index.561d1d66.css.map */
