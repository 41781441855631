:root {
  --primary-color: #05b1d0;
  --dark-color: #000410;
  --light-color: #ffffff;
}

@font-face {
  font-family: "Inter";
  src: url("fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}

html, body {
  overflow-x: hidden;
  max-width: 100vw;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #18191F;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

.h4 {
  font-size: 20px;
  font-weight: 800;
}

.lead {
  font-size: 30px;
  font-weight: 400;
}

p {
  color: #18191F;
  line-height: 1.7;
  font-size: 18px;
  font-weight: 400;
}

header a, footer a {
  margin-right: 1.5em;
  color: var(--light-color);
  text-decoration: none;
}

header a:hover, header a:focus, footer a:hover, footer a:focus {
  color: var(--primary-color);
}

.header-bg {
  background-color: var(--dark-color);
  background-image: url("img/header_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
}

.header-bg h1 {
  font-weight: 800;
  font-size: 72px;
  color: var(--light-color);
}

.header-bg .lead {
  letter-spacing: 0.5px;
  font-size: 20px;
  color: var(--light-color);
}

.phone-demo {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 320px;
  height: 640px;
}

.phone-demo .phone-screen {
  top: 17px;
  z-index: 0;
  position: absolute;
  width: 279px;
  height: 604px;
}

.phone-demo .phone-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.phone-demo.with-hand {
  width: 504px;
  height: 599px;
  background-size: contain;
  background-image: url("img/hand.png");
  background-repeat: no-repeat;
  padding-right: 165px;
}

.phone-demo.with-hand .phone-overlay {
  width: 240px;
  height: 485px;
}

.phone-demo.with-hand .phone-screen {
  top: 14px;
  width: 214px;
  height: 462px;
}

.numbered-bullet {
  background-color: var(--primary-color);
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  margin: 0 auto;
}

.score-arrow:before,
.fakenews-underline:before,
.authentic-profile:before {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.score-arrow:before {
  background-image: url("img/arrow.svg");
  width: 170px;
  height: 200px;
  -webkit-transform: translateX(-200px) translateY(100px);
          transform: translateX(-200px) translateY(100px);
}

.fakenews-underline:before {
  background-image: url("img/underline.svg");
  width: 250px;
  height: 70px;
  -webkit-transform: translateX(-25px) translateY(15px);
          transform: translateX(-25px) translateY(15px);
}

.authentic-profile:before {
  background-image: url("img/sparkle.png");
  width: 125px;
  height: 100px;
  -webkit-transform: translateX(340px) translateY(-115px);
          transform: translateX(340px) translateY(-115px);
}

.arrow-right {
  max-width: 480px;
  position: relative;
  margin-left: 25px;
  margin-right: 150px;
}

.arrow-right:after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url("img/right-arrow-straight.svg");
  width: 142px;
  height: 46px;
  top: 250px;
  right: -100px;
}

.half-width {
  max-width: 500px;
}

footer {
  color: white;
  background-color: var(--dark-color);
}

footer nav {
  max-width: 300px;
}

footer p {
  color: var(--light-color);
}

footer a {
  display: inline-block;
  margin-bottom: 1.5em;
}

@media (max-width: 1199px) {
  .authentic-profile:before {
    background-image: none;
    transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
  }
}

@media (max-width: 991px) {
  .header-bg h1 {
    font-size: 57px;
  }
  .half-width {
    max-width: none;
  }
  .arrow-right {
    margin: 0 auto;
  }
  .arrow-right:after {
    background-image: none;
    transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
  }
}

@media (max-width: 767px) {
  .header-bg h1 {
    font-size: 42px;
  }
  .score-arrow:before {
    background-image: none;
    transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  .h4 {
    font-size: 17px;
  }
  p {
    font-size: 15px;
  }
  .lead {
    font-size: 17px;
  }
  .header-bg h1 {
    font-size: 34px;
  }
  .header-bg .lead {
    font-size: 17px;
  }
  .fakenews-underline:before {
    width: 150px;
  }
  .phone-demo {
    width: 227px;
    height: 454px;
  }
  .phone-demo .phone-screen {
    width: 197px;
    height: 428px;
    top: 13px;
  }
  .phone-demo.with-hand {
    width: 294px;
    height: 349px;
    padding-right: 95px;
  }
  .phone-demo.with-hand .phone-overlay {
    width: 140px;
    height: 283px;
  }
  .phone-demo.with-hand .phone-screen {
    width: 125px;
    height: 269px;
    top: 7px;
  }
  .fakenews-underline:before {
    -webkit-transform: translateX(-25px) translateY(8px);
            transform: translateX(-25px) translateY(8px);
  }
}
